<template>
  <v-container fluid>
    <PageHeader>
      <span slot="courses" class="font-weight-light">NEW </span> DEPARTMENT
    </PageHeader>

    <v-overlay :absolute="absolute" :value="overlay" z-index="90">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-row>
      <v-col>
        <v-btn
          class="ma-2 secondary--text font-weight-bold"
          to="/department_management/departmentlist"
          color="primary"
        >
          DEPARTMENT LIST
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <v-form ref="form" lazy-validation v-model="valid">
              <v-row align="center" justify="center">
                <v-col cols="12" lg="5">
                  <v-autocomplete
                    @keyup="debouncesearchFaculty"
                    :loading="isLoaded"
                    v-model="faculty_id"
                    :items="itemsFaculty"
                    :search-input.sync="searchFaculty"
                    clearable
                    hide-details="auto"
                    hide-selected
                    class="rounded-0"
                    item-text="faculty_name"
                    item-value="id"
                    label="Search Faculty/Directorate"
                    outlined
                    :rules="[(v) => !!v || 'Faculty is required...']"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title>
                          Search
                          <strong>Faculty/Directorate... </strong>
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" lg="3">
                  <v-text-field
                    outlined
                    clearable
                    class="rounded-0"
                    label="Department/Unit Code"
                    :rules="[(v) => !!v || 'Department Code is required']"
                    min="0"
                    v-model="department_code"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" lg="4">
                  <v-text-field
                    outlined
                    class="rounded-0"
                    clearable
                    label="Department/Unit Name"
                    :rules="[(v) => !!v || 'Department Name/Unit is required']"
                    hide-details="auto"
                    v-model="department_name"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row justify="center" align="center">
                <v-col cols="12" lg="2">
                  <v-btn
                    color="primary"
                    :disabled="!valid"
                    @click="saveDepartmentBtn"
                    v-if="
                      getters_abilities.includes('create_department_access')
                    "
                  >
                    save department
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <Error v-if="deleteResponse">
      <template v-slot:header>{{ msgHeader }}</template>
      <template v-slot:body>{{ msgBody }}</template>
      <template v-slot:icon>{{ msgIcon }}</template>
    </Error>
    <Success v-if="successFeedback">
      <template v-slot:header>{{ msgHeader }}</template>
      <template v-slot:body>{{ msgBody }}</template>
      <template v-slot:icon>{{ msgIcon }}</template>
    </Success>
  </v-container>
</template>
<script>
  import { reactive, ref, toRefs } from "vue";
  import { useActions, useGetters } from "vuex-composition-helpers";
  import PageHeader from "@/components/slots/PageHeader";
  import debounce from "@/debounce/debounce";
  import Error from "@/components/Error/Error";
  import Success from "@/components/Error/Success";

  export default {
    components: { PageHeader, Error, Success },
    setup() {
      const { /* checkDepartmentAvail ,*/ saveDepartment, getFaculty } =
        useActions(["checkDepartmentAvail", "saveDepartment", "getFaculty"]);

      const {
        /* getters_checkdepartmentavail ,*/ getters_faculty,
        getters_abilities,
      } = useGetters([
        /*  "getters_checkdepartmentavail", */
        "getters_faculty",
        "getters_abilities",
      ]);

      const form = ref(null);

      const departmentForm = reactive({
        valid: true,
        isLoaded: false,
        department_code: null,
        department_name: null,
        searchFaculty: null,
        error_msgs: [],
        checkAvalailbity: "",
        isAvailable: false,
        basket: {},
        load: true,
        itemsFaculty: [],
        faculty_id: 0,
        absolute: true,
        overlay: false,
        msgHeader: "",
        msgBody: "",
        msgIcon: "",
        deleteResponse: false,
        successFeedback: false,
      });

      let {
        successFeedback,
        isLoaded,
        department_code,
        department_name,
        /* 	error_msgs, */
        checkAvalailbity,
        isAvailable,
        basket,
        searchFaculty,
        itemsFaculty,
        faculty_id,
        overlay,
        deleteResponse,
        msgHeader,
        msgBody,
        msgIcon,
      } = toRefs(departmentForm);

      const debouncesearchFaculty = debounce(() => {
        if (searchFaculty.value) {
          if (itemsFaculty.value.length > 0) return;
          isLoaded.value = true;

          getFaculty()
            .then(() => {
              itemsFaculty.value = getters_faculty.value;
            })
            .finally(() => (isLoaded.value = false));
        }
      });

      const duplicateDepartment = debounce(() => {
        checkAvalailbity.value = "authenticating... ";
        isAvailable.value = true;

        /* 	checkDepartmentAvail(
                				parseInt(faculty_id.value + "" + department_code.value)
                			).then(() => {
                				isAvailable.value = false;
                				let item =
                					"Department CODE " + department_code.value + " already exists...";
                				if (getters_checkdepartmentavail.value.is_valid) {
                					error_msgs.value.push(item);
                				} else {
                					error_msgs.value = [];
                				}
                				checkAvalailbity.value = "";
                			}); */
      });

      const saveDepartmentBtn = () => {
        deleteResponse.value = false;
        successFeedback.value = false;

        if (form.value.validate()) {
          overlay.value = true;

          basket.value = {
            department_code: department_code.value,
            department_name: department_name.value,
            faculty_id: parseInt(faculty_id.value),
          };
          console.log(basket.value);
          saveDepartment(basket.value)
            .then(() => {
              form.value.reset();
              basket.value = {};
              overlay.value = false;

              successFeedback.value = true;
              msgHeader.value = "Success";
              msgBody.value = "Saved";
              msgIcon.value = "mdi-check-circle";
            })
            .catch((e) => {
              form.value.reset();
              overlay.value = false;
              deleteResponse.value = true;
              msgHeader.value = "Error";
              msgIcon.value = "mdi-close-circle";

              if (e.response.status === 422) {
                msgBody.value =
                  "Either the Department CODE or the Department name exists.";
              } else if (e.response.status === 403) {
                msgBody.value = "This action is unauthorized";
              } else {
                msgBody.value =
                  "Try Again Later or Call The System Administrator";
              }
            });
        }
      };

      return {
        ...toRefs(departmentForm),
        itemsFaculty,
        saveDepartmentBtn,
        duplicateDepartment,
        debouncesearchFaculty,
        form,
        getters_abilities,
      };
    },
  };
</script>
